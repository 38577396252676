import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./CampsMobile.css"
import AM from "../Assets/2.svg"
import PM from "../Assets/1.svg"
import one from "../Assets/Icons/stop motion animation.svg"
import two from "../Assets/Icons/coding arcade tycoon.svg"
import three from "../Assets/Icons/become a youtuber.svg"
import four from "../Assets/Icons/mm redstone realm.svg"
import five from "../Assets/Icons/ninja robotics w lego.svg"
import six from "../Assets/Icons/mc modding masters.svg"
import seven from "../Assets/Icons/intro to roblox dev.svg"
import eight from "../Assets/Icons/future minds ai academy.svg"
import nine from "../Assets/Icons/roblox world creators.svg"
import ten from "../Assets/Icons/3d design and print.svg"
import eleven from "../Assets/Icons/cc esports.svg"
import twelve from "../Assets/Icons/code your own cartoons.svg"
import thirteen from "../Assets/Icons/adventures in gd.svg"
import fourteen from "../Assets/Icons/operation code breakers.svg"
import fifteen from "../Assets/Icons/3d design and print.svg"
import sixteen from "../Assets/Icons/creative design studio.svg"
import seventeen from "../Assets/Icons/jr inventors.svg"
import eighteen from "../Assets/Icons/jr adventures in gd.svg"
import stopMotion from "../Assets/CNF 2024 Summer Camp Descriptions/stop motion animation.png"
import codingTycoon from "../Assets/CNF 2024 Summer Camp Descriptions/coding arcade tycoon.png"
import youtuber from "../Assets/CNF 2024 Summer Camp Descriptions/become a youtuber.png"
import redstone from "../Assets/CNF 2024 Summer Camp Descriptions/mc redstone realm.png"
import ninja from "../Assets/CNF 2024 Summer Camp Descriptions/ninja robotics with lego.png"
import minecraft from "../Assets/CNF 2024 Summer Camp Descriptions/mc modding masters.png"
import roblox from "../Assets/CNF 2024 Summer Camp Descriptions/intro to roblox.png"
import AI from "../Assets/CNF 2024 Summer Camp Descriptions/future minds ai academy.png"
import robloxWorld from "../Assets/CNF 2024 Summer Camp Descriptions/roblox world creators.png"
import design from "../Assets/CNF 2024 Summer Camp Descriptions/3d print and design.png"
import esports from "../Assets/CNF 2024 Summer Camp Descriptions/cc esports.png"
import cartoon from "../Assets/CNF 2024 Summer Camp Descriptions/code your own cartoons.png"
import adventure from "../Assets/CNF 2024 Summer Camp Descriptions/adventures in gd.png"
import operation from "../Assets/CNF 2024 Summer Camp Descriptions/operation code breakers.png"
import dojoDesign from "../Assets/CNF 2024 Summer Camp Descriptions/dojo design 3d printing.png"
import studio from "../Assets/CNF 2024 Summer Camp Descriptions/creative design studio.png"
import jrStem from "../Assets/CNF 2024 Summer Camp Descriptions/JR inventors workshop.png"
import jrAdventure from "../Assets/CNF 2024 Summer Camp Descriptions/JR adventures in gd.png"
import beginner from "../Assets/beginner.png"


export default function CampsMobile() {

    var amButton = (<img src={AM} alt="clickable AM icon" />);
    var pmButton = (<img src={PM} alt="clickable PM icon" />); 
  
    const [hovering, setHovering] = useState(false); 
    const [campSource, setCampSource] = useState(null);
  
    const handleMouseOver = (campSourceProp) => {
      setHovering(false);
      if (hovering){
        console.log(campSource);
      }
    }
  
    const handleMouseLeave = () => {
      setHovering(false)
    }

  return (
    <div className="mobile-layout">
        <Tabs>
            <TabList>
                <Tab>June</Tab>
                <Tab>July</Tab>
                <Tab>August</Tab>
            </TabList>
            <TabPanel>

          <div className="grid-layout-mobile">

            <div className="item1">
              <h1>CAMPS</h1>
            </div>
              <h5>JUN<br></br> 3-7</h5>
              <h5>JUN<br></br> 10-14</h5>
              <h5>JUN<br></br> 17-21</h5>
              <h5>JUN<br></br> 24-28</h5>
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(stopMotion);}} onMouseLeave={handleMouseLeave}>
                <img src={one} alt="camp icon" />
                <h4>STOP MOTION ANIMATION</h4>
                
              </div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89281//1708375145" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(codingTycoon);}} onMouseLeave={handleMouseLeave}><img src={two} alt="camp icon" /><h4>CODING ARCADE TYCOON</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89272//1707161262" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(youtuber);}} onMouseLeave={handleMouseLeave}><img src={three} alt="camp icon" /><h4>BECOME A YOUTUBER</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116489//1708375141" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(redstone);}} onMouseLeave={handleMouseLeave}><img src={four} alt="camp icon" /><h4>MINECRAFT MASTERY: REDSTONE REALM</h4></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89276//1707161267" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(ninja);}} onMouseLeave={handleMouseLeave}><img src={five} alt="camp icon" /><h4>NINJA ROBOTICS WITH LEGO</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116488//1708375163" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(minecraft);}} onMouseLeave={handleMouseLeave}><img src={six} alt="camp icon" /><h4>MINECRAFT® MODDING MASTERS</h4></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116486//1708375171" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(roblox);}} onMouseLeave={handleMouseLeave}><img src={seven} alt="camp icon" /><h4>INTRO TO ROBLOX DEVELOPMENT</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89291//1707161249" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(AI);}} onMouseLeave={handleMouseLeave}><img src={eight} alt="camp icon" /><h4>FUTURE MINDS AI ACADEMY</h4></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89277//1707161258" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(robloxWorld);}} onMouseLeave={handleMouseLeave}><img src={nine} alt="camp icon" /><h4>ROBLOX WORLD CREATORS</h4></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116487//1708375167" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(design);}} onMouseLeave={handleMouseLeave}><img src={ten} alt="camp icon" /><h4>3D PRINT AND DESIGN</h4></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/110154//1707161271" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(esports);}} onMouseLeave={handleMouseLeave}><img src={eleven} alt="camp icon" /><h4>COMPETITIVE CODING: ESPORTS & GAME DESIGN</h4></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/112802//1708375158" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(cartoon);}} onMouseLeave={handleMouseLeave}><img src={twelve} alt="camp icon" /><h4>CODE YOUR OWN CARTOONS</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89284//1708375149" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(adventure);}} onMouseLeave={handleMouseLeave}><img src={thirteen} alt="camp icon" /><h4>ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89287//1708375153" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(operation);}} onMouseLeave={handleMouseLeave}><img src={fourteen} alt="camp icon" /><h4>OPERATION: CODE BREAKERS(10+)</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(dojoDesign);}} onMouseLeave={handleMouseLeave}><img src={fifteen} alt="camp icon" /><h4>DOJO DESIGN & 3D PRINTING(10+)</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(studio);}} onMouseLeave={handleMouseLeave}><img src={sixteen} alt="camp icon" /><h4>CREATIVE DESIGN STUDIO(10+)</h4></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89278//1707161253" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrStem);}} onMouseLeave={handleMouseLeave}><img src={seventeen} alt="camp icon" /><h4>JR INVENTORS WORKSHOP</h4><img src={beginner} alt="beginner friendly" /></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89269//1707159219" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrAdventure);}} onMouseLeave={handleMouseLeave}><img src={eighteen} alt="camp icon" /><h4>JR ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89286//1707161244" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              

          </div>
            </TabPanel>

            <TabPanel>
            <div className="grid-layout-mobile">

<div className="item1">
  <h1>CAMPS</h1>
</div>
    <h5>JUL<br></br> 8-12</h5>
    <h5>JUL<br></br> 15-19</h5>
    <h5>JUL<br></br> 22-26</h5>
    <h5>JUL 29-AUG 2</h5>
    <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(stopMotion);}} onMouseLeave={handleMouseLeave}>
                <img src={one} alt="camp icon" />
                <h4>STOP MOTION ANIMATION</h4>
                
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(codingTycoon);}} onMouseLeave={handleMouseLeave}><img src={two} alt="camp icon" /><h4>CODING ARCADE TYCOON</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(youtuber);}} onMouseLeave={handleMouseLeave}><img src={three} alt="camp icon" /><h4>BECOME A YOUTUBER</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89280//1707161206" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(redstone);}} onMouseLeave={handleMouseLeave}><img src={four} alt="camp icon" /><h4>MINECRAFT MASTERY: REDSTONE REALM</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116496//1708375113" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>

              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(ninja);}} onMouseLeave={handleMouseLeave}><img src={five} alt="camp icon" /><h4>NINJA ROBOTICS WITH LEGO</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89273//1707161215" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(minecraft);}} onMouseLeave={handleMouseLeave}><img src={six} alt="camp icon" /><h4>MINECRAFT® MODDING MASTERS</h4></div>
              
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89275//1707161236" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(roblox);}} onMouseLeave={handleMouseLeave}><img src={seven} alt="camp icon" /><h4>INTRO TO ROBLOX DEVELOPMENT</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/108958//1707161228" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(AI);}} onMouseLeave={handleMouseLeave}><img src={eight} alt="camp icon" /><h4>FUTURE MINDS AI ACADEMY</h4></div>
        
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116494//1708375127" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>

              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(robloxWorld);}} onMouseLeave={handleMouseLeave}><img src={nine} alt="camp icon" /><h4>ROBLOX WORLD CREATORS</h4></div>
              
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89274//1707161211" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(design);}} onMouseLeave={handleMouseLeave}><img src={ten} alt="camp icon" /><h4>3D PRINT AND DESIGN</h4></div>
        
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116493//1708375123" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(esports);}} onMouseLeave={handleMouseLeave}><img src={eleven} alt="camp icon" /><h4>COMPETITIVE CODING: ESPORTS & GAME DESIGN</h4></div>
            
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116491//1708375096" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>


              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(cartoon);}} onMouseLeave={handleMouseLeave}><img src={twelve} alt="camp icon" /><h4>CODE YOUR OWN CARTOONS</h4><img src={beginner} alt="beginner friendly" /></div>

              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(adventure);}} onMouseLeave={handleMouseLeave}><img src={thirteen} alt="camp icon" /><h4>ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>

              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116492//1708375132" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(operation);}} onMouseLeave={handleMouseLeave}><img src={fourteen} alt="camp icon" /><h4>OPERATION: CODE BREAKERS(10+)</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(dojoDesign);}} onMouseLeave={handleMouseLeave}><img src={fifteen} alt="camp icon" /><h4>DOJO DESIGN & 3D PRINTING(10+)</h4></div>
            
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/89279//1707161232" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(studio);}} onMouseLeave={handleMouseLeave}><img src={sixteen} alt="camp icon" /><h4>CREATIVE DESIGN STUDIO(10+)</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116495//1708375118" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrStem);}} onMouseLeave={handleMouseLeave}><img src={seventeen} alt="camp icon" /><h4>JR INVENTORS WORKSHOP</h4><img src={beginner} alt="beginner friendly" /></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116490//1708375092" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrAdventure);}} onMouseLeave={handleMouseLeave}><img src={eighteen} alt="camp icon" /><h4>JR ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <a href="https://cn.mystudio.io/e/?=6572582633/299/108960//1707161223" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>

  

</div>
            </TabPanel>

            <TabPanel>
            <div className="grid-layout-mobile">

            <div className="item1">
            <h1>CAMPS</h1>
            </div>
                <h5>AUG<br></br> 5-9</h5>
                <div><h5>X</h5></div>
                <div><h5>X</h5></div>
                <div><h5>X</h5></div>
    
            <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(stopMotion);}} onMouseLeave={handleMouseLeave}>
                <img src={one} alt="camp icon" />
                <h4>STOP MOTION ANIMATION</h4>
                
              </div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(codingTycoon);}} onMouseLeave={handleMouseLeave}><img src={two} alt="camp icon" /><h4>CODING ARCADE TYCOON</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(youtuber);}} onMouseLeave={handleMouseLeave}><img src={three} alt="camp icon" /><h4>BECOME A YOUTUBER</h4><img src={beginner} alt="beginner friendly" /></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(redstone);}} onMouseLeave={handleMouseLeave}><img src={four} alt="camp icon" /><h4>MINECRAFT MASTERY: REDSTONE REALM</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(ninja);}} onMouseLeave={handleMouseLeave}><img src={five} alt="camp icon" /><h4>NINJA ROBOTICS WITH LEGO</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(minecraft);}} onMouseLeave={handleMouseLeave}><img src={six} alt="camp icon" /><h4>MINECRAFT® MODDING MASTERS</h4></div>
              
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116498//1708375105" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(roblox);}} onMouseLeave={handleMouseLeave}><img src={seven} alt="camp icon" /><h4>INTRO TO ROBLOX DEVELOPMENT</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116497//1708375109" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(AI);}} onMouseLeave={handleMouseLeave}><img src={eight} alt="camp icon" /><h4>FUTURE MINDS AI ACADEMY</h4></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              
              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(robloxWorld);}} onMouseLeave={handleMouseLeave}><img src={nine} alt="camp icon" /><h4>ROBLOX WORLD CREATORS</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(design);}} onMouseLeave={handleMouseLeave}><img src={ten} alt="camp icon" /><h4>3D PRINT AND DESIGN</h4></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(esports);}} onMouseLeave={handleMouseLeave}><img src={eleven} alt="camp icon" /><h4>COMPETITIVE CODING: ESPORTS & GAME DESIGN</h4></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(cartoon);}} onMouseLeave={handleMouseLeave}><img src={twelve} alt="camp icon" /><h4>CODE YOUR OWN CARTOONS</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(adventure);}} onMouseLeave={handleMouseLeave}><img src={thirteen} alt="camp icon" /><h4>ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(operation);}} onMouseLeave={handleMouseLeave}><img src={fourteen} alt="camp icon" /><h4>OPERATION: CODE BREAKERS(10+)</h4></div>

              <a href="https://cn.mystudio.io/e/?=6572582633/299/89290//1707161202" target="blank" alt="camp"><div className="time-button">{amButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(dojoDesign);}} onMouseLeave={handleMouseLeave}><img src={fifteen} alt="camp icon" /><h4>DOJO DESIGN & 3D PRINTING(10+)</h4></div>
              
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(studio);}} onMouseLeave={handleMouseLeave}><img src={sixteen} alt="camp icon" /><h4>CREATIVE DESIGN STUDIO(10+)</h4></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrStem);}} onMouseLeave={handleMouseLeave}><img src={seventeen} alt="camp icon" /><h4>JR INVENTORS WORKSHOP</h4><img src={beginner} alt="beginner friendly" /></div>
      
              <a href="https://cn.mystudio.io/e/?=6572582633/299/116499//1708375102" target="blank" alt="camp"><div className="time-button">{pmButton}</div></a>
              <div></div>
              <div></div>
              <div></div>

              <div className="camp-category" onMouseEnter={() => {
                handleMouseOver();
                setCampSource(jrAdventure);}} onMouseLeave={handleMouseLeave}><img src={eighteen} alt="camp icon" /><h4>JR ADVENTURES IN GAME DESIGN</h4><img src={beginner} alt="beginner friendly" /></div>
             
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              </div>
            </TabPanel>
        </Tabs>
    </div>
  )
}
