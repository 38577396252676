import React from 'react';
import './Nav.css'
import cnLogo from '../Assets/fullertonlogo (2).svg'

export default function Nav() {

    

  return (
    <div className="nav">
        <div className="main-page-link" >
            <a href="/">
                <img src={cnLogo} alt="code ninjas" />
            </a>
        </div>

      <div className="nav-item">
      <div className="dropdown">
              <div className="wrap">
                <h6>Programs</h6><p>↓</p>
                <div className="rectangle">
                </div>
              </div>
            <div className="dropdown-content">
            <a href="https://www.codeninjas.com/ca-fullerton/create" target="blank">
                <div className="dropdown-button">
                  CREATE (Ages 8+)
                </div>
            </a>
              
            <a href="https://www.codeninjas.com/ca-fullerton/jr" target="blank">
                <div className="dropdown-button">
                  JR (Ages 5-7)
                </div>
            </a>

            <a href="https://www.codeninjas.com/ca-fullerton/home-schooling" target="blank">
                <div className="dropdown-button">
                  Home Schooling
                </div>
            </a>
             
            </div>
          </div>
      </div>
      <div className="nav-item">
        <a href="/camps">Camps</a>
      </div>
      <div className="nav-item">
        <a href="/contact">Contact Us</a>
      </div>
    </div>
  )
}
