import React from 'react'
import Programs from '../Components/Programs.jsx'
import './Homepage.css'
import homeBanner from '../Assets/homeheader.png'
import homeImage from '../Assets/cn-create-problems.png'
import camps from '../Assets/signup.svg'
import hypeImage from '../Assets/aboutus.png'

export default function Homepage() {
  return (
    <div className="Homepage">
        <div className="banner">
            <img src={homeBanner} alt="homepage" />
        </div>
        <div className="welcome-intro">
            <div className="text-intro">
                <h1>Code Ninjas Fullerton</h1>
                <h5>1472 S Harbor Blvd Suite 12, La Habra, CA 90631</h5>
                <h6>Where Young Minds Become Coding Masters</h6>
                <p>Every day thousands our Ninjas enter our Dojo to learn to code on our proprietary learning platform – and in the process UNLEASH THEIR INNER NINJA!

                    
                </p>
                <br></br>
                <p>We offer a unique learning environment designed to make 
                    coding fun & engaging for kids ages 5-14.
                </p>
                <div className="CTA">
                    <h2>Join our class today!</h2>
                    <div className="hero-buttons">
                        <form action="https://www.codeninjas.com/ca-fullerton/schedule-tour">
                            <button type="submit">BOOK A FREE SESSION</button>
                        </form>
                        <form action="/contact">
                            <button type="submit">REQUEST INFO</button>
                        </form>
                    </div>
                </div>
                
            </div>
            <div className="image-intro">
                <img src={homeImage} alt="victory" />
            </div>
        </div>
        <Programs />
        <div className="invisible-helper"></div>
        <div className="invisible-helper2"></div>
        <div className="invisible-helper3"></div>
        <div className="invisible-helper4"></div>
        <div className="invisible-helper5"></div>

        <div className="camps-info">
                <div className="current-ad">
                    <h3>OUR SUMMER CAMPS ARE LIVE!</h3>
                    <h3>ENROLL NOW! ↓</h3>
                    <a href="camps"><img src={camps} alt="camps"/></a>
                </div>
                <div className="camps-text">
                    <h3>Camps (Ages 5-14)</h3>
                    <p>Looking for something a little different? Check out our seasonal learning experiences in our camps section. From Roblox to Coding your own Cartoons and even Becoming a Youtuber, we have specialized content that your child is certain to love!</p>
                </div>
        </div>
        <div className="camps-info-bg"><img src={hypeImage} alt="inside the dojo" /></div>
        <div className="camps-info-darken"></div>

        <div className="footer-banner">
            <img src={homeBanner} alt="welcome to code ninjas fullerton"/>
        </div>
        <div className="footer-notes">
            <h1>We can't wait to meet your child!</h1>
            <h1>Have any questions for us?</h1>
            <form action="/contact">
                <button type="submit">Get in Touch!</button>
            </form>
            <p>© 2024 Code Ninjas Fullerton, All Rights Reserved</p>
        </div>
    </div>
  )
}
